

























import { Component, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

interface ClassMode {
  id: number
  name: string
  is_mode_default?: boolean
}

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    ColoredBox,
    RadioToggle,
    ButtonBase,
  },
})
export default class ListMode extends Vue {
  private breadcrumbs = [
    { text: TEACHER_SETTING_TEXT, href: '/teacher/setting/top' },
    { text: 'モード確認・追加・変更', active: true },
  ]

  private listMode: ClassMode[] = []
  private branchId = Vue.prototype.$cookies.get('dataGdls').branchId

  private get isMaxMode() {
    return this.listMode.length >= 49
  }

  private get listModeProcess() {
    const _listMode = [...this.listMode]
    for (const index in _listMode) {
      if (_listMode[index].is_mode_default) {
        const removedItem = _listMode.splice(+index, 1)[0]
        _listMode.unshift(removedItem)
        break
      }
    }
    return _listMode
  }

  private confirmDeleteMode(mode: ClassMode) {
    const result = confirm(`${mode.name}を削除してよろしいですか?`)
    if (result == true) {
      this.deleteMode(mode.id)
    }
  }

  private async deleteMode(id: string | number) {
    const res = await Vue.prototype.$http.httpWithToken.delete(`/v3/class_modes/${id}`)
    if (res.status == 204) {
      this.fetchListMode()
    } else {
      alert('システムエラーが発生しましたので、後でもう一度お試しください。')
    }
  }

  private async fetchListMode() {
    Vue.prototype.$loading.start()
    const {
      data: { class_modes },
    } = await Vue.prototype.$http.httpWithToken.get('/v3/class_modes', {
      params: { branchId: this.branchId, page: 1, limit: 1000 },
    })
    this.listMode = class_modes
    Vue.prototype.$loading.complete()
  }

  private mounted() {
    this.fetchListMode()
  }
}
